import { Box, Heading, Text } from '@chakra-ui/react';

// pages/404.tsx
export default function Custom404() {
  return (
    <Box p={32}>
      <Text fontFamily='mono' mb={4}>
        404
      </Text>
      <Heading fontFamily='body'>Cannot find content. 😔</Heading>
    </Box>
  );
}
